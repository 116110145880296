<template>
	<div class="container">
		<back></back>
		<img style="width: 100%;height: 100%;object-fit: cover;pointer-events: none;" :src="imgUrl" alt="">
	</div>
</template>

<script>
	import back from "@/components/back";
	export default {
		components: {
			back
		},
		data() {
			return {
				imgUrl: ''
			}
		},
		created() {
			this.imgUrl = this.$route.query.imgUrl
		},
	}
</script>

<style lang="scss" scoped>
</style>
